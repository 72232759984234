<template>
  <div class="panel-page-menu">
    <div class="container">
      <div class="menu-buttons">
        <router-link :class="activePath == '/dashboard' ? 'active' : ''" to="/dashboard">Dashboard</router-link>
        <router-link :class="activePath == '/orders' ? 'active' : ''" to="/orders">Orders</router-link>
        <router-link :class="activePath == '/plans' ? 'active' : ''" to="/plans">Plans</router-link>
        <router-link :class="activePath == '/address-book' ? 'active' : ''" to="/address-book">Address Book</router-link>
        <router-link :class="activePath == '/deposits' ? 'active' : ''" to="/deposits">Deposits</router-link>
        <router-link to="/support">Support</router-link>
        <div class="profile-link">
          <router-link to="/profile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-person-circle"
              viewBox="0 0 16 16"
            >
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                fill-rule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
              />
            </svg>
            Profile</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activePath: "",
    }
  },
  mounted() {
    this.activePath = this.$router.currentRoute.fullPath;
  },
};
</script>

<style></style>
