<template>
  <div>
    <br />
    <br />
    <br />

    <div class="newsletter">
      <div class="container">
        <div class="nl-box">
          <div class="text">
            <h3>Try it out now!</h3>
            <p>Start Shipping!</p>
          </div>
          <div class="buttons">
            <a href="#"> Get Started </a>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="footer-info">
              <img src="images/logoipsum.svg" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Company</strong>
                  <a href="#">Blog</a>
                  <a href="#">Help Center</a>
                  <a href="#">Sales Tools Catalog</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Support </strong>
                  <a href="#">Partners</a>
                  <a href="#">Policy</a>
                  <a href="#">Programs</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Get in touch</strong>
                  <a href="#">Support</a>
                  <a href="#">About us</a>
                  <a href="#">FAQ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>© 2023 All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
