<template>
  <div>
    <Header></Header>
    <PanelMenu></PanelMenu>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-table">
              <div class="head">
                <h3>Orders</h3>
                <div class="d-flex">
                  <router-link to="/orders/create">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      /></svg
                    >&nbsp; New Order
                  </router-link>
                  <router-link to="/orders">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      /></svg
                    >&nbsp; New Bulk Order
                  </router-link>
                </div>
              </div>
              <div class="content p-3">
                <div class="filter-search">
                  Filters:&nbsp;&nbsp;
                  <input
                    type="text"
                    placeholder="Search.."
                    v-model="searchText"
                    @input="searchTable"
                  />
                  From:&nbsp;&nbsp;
                  <input
                    type="date"
                    placeholder="From Date"
                    v-model="searchFrom"
                    @change="searchWithDate"
                  />
                  To:&nbsp;&nbsp;
                  <input
                    type="date"
                    placeholder="To Date"
                    v-model="searchTo"
                    @change="searchWithDate"
                  />
                  <button
                    class="btn btn-primary"
                    style="height: 40px"
                    @click="exportTable()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                      />
                      <path
                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
                      /></svg
                    >&nbsp;&nbsp; Export
                  </button>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Courier</th>
                    <th>Shipment Count</th>
                    <th>Total Cost</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody v-if="!isTableLoading && orders.length > 0">
                  <tr v-for="(order, i) in orders" :key="i">
                    <td>
                      <p>{{ order.courier_code }}</p>
                    </td>
                    <td>
                      <p>{{ order.shipment_count }} shipments</p>
                    </td>
                    <td>
                      <p>{{ order.cost | toCurrency }}</p>
                    </td>
                    <td>
                      <span :class="'status text-uppercase ' + order.status">{{
                        order.status
                      }}</span>
                    </td>
                    <td>
                      <p>{{ order.created_at | formatDate }}</p>
                    </td>
                    <td>
                      <div class="d-flex">
                        <button @click="viewDetails(order.id)">View Details</button>
                        <!--  <button v-if="order.status == 'done'" @click="downloadLabel(order.id)">Download</button> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="text p-5 text-center text-white d-block"
                v-if="orders.length == 0 && !isTableLoading"
              >
                No records found! Please try again.
              </div>
              <div class="spinner-wrap" v-if="isTableLoading">
                <b-spinner variant="light" class="mr-2" medium></b-spinner>
                <div class="text">Loading please wait...</div>
              </div>
              <div class="table-bottom">
                <select @change="limitTable()" v-model="limit">
                  <option value="10">10 records</option>
                  <option value="20">20 records</option>
                  <option value="50">50 records</option>
                  <option value="100">100 records</option>
                </select>
                <div class="table-pagination">
                  <button
                    @click="changePage(page)"
                    :class="current_page == page ? 'active' : ''"
                    v-for="(page, i) in total_pages"
                    :key="i"
                  >
                    {{ page }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-modal-bg" @click="showModal = false" v-if="showModal"></div>
    <div class="c-modal modal-xl" v-if="showModal">
      <div class="c-modal-head">
        <div class="title">Order Details</div>
        <button @click="showModal = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
            />
          </svg>
        </button>
      </div>
      <div class="spinner-wrap" v-if="isLoading">
        <b-spinner variant="light" class="mr-2" medium></b-spinner>
        <div class="text">Loading please wait...</div>
      </div>
      <div class="c-modal-content" v-if="!isLoading">
        <div class="order-details">
          <ul>
            <div class="row">
              <div class="col-3">
                <li>
                  <strong>Courier</strong>
                  <span>{{ orderDetails.courier_code }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Cost</strong>
                  <span>{{ orderDetails.cost | toCurrency }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Status</strong>
                  <span :class="'status text-uppercase ' + orderDetails.status">{{
                    orderDetails.status
                  }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Created At</strong>
                  <span>{{ orderDetails.created_at | formatDateWithHours }}</span>
                </li>
              </div>
            </div>
          </ul>
          <h4 class="d-block mb-3">Shipments</h4>
          <div class="shipment" v-for="(shipment, i) in orderDetails.shipments" :key="i">
            <ul>
              <div class="row">
                <div class="col-6 mb-3">
                  <li>
                    <strong>Sender</strong>
                    <span><b>Fullname: </b> {{ shipment.sender.fullname }}</span>
                    <span><b>Country: </b> {{ shipment.sender.country }}</span>
                    <span
                      ><b>Address: </b>{{ shipment.sender.city }},
                      {{ shipment.sender.country }} {{ shipment.sender.line1 }}
                      {{ shipment.sender.line2 }}, {{ shipment.sender.zip }}</span
                    >
                    <span v-if="shipment.sender.company"
                      ><b>Company: </b> {{ shipment.sender.company }}</span
                    >
                  </li>
                </div>
                <div class="col-6 mb-3">
                  <li>
                    <strong>Recipient</strong>
                    <span><b>Fullname: </b> {{ shipment.recipient.fullname }}</span>
                    <span><b>Country: </b> {{ shipment.recipient.country }}</span>
                    <span
                      ><b>Address: </b>{{ shipment.recipient.city }},
                      {{ shipment.recipient.country }} {{ shipment.recipient.line1 }}
                      {{ shipment.recipient.line2 }}, {{ shipment.recipient.zip }}</span
                    >
                    <span v-if="shipment.recipient.company"
                      ><b>Company: </b> {{ shipment.recipient.company }}</span
                    >
                  </li>
                </div>
                <div class="col-4">
                  <li>
                    <strong>Package</strong>
                    <span
                      ><b style="min-width: 0">W:</b>{{ shipment.package.width }},
                      <b style="min-width: 0">H:</b> {{ shipment.package.height }},
                      <b style="min-width: 0">L:</b> {{ shipment.package.length }},
                      <b style="min-width: 0">Weight:</b>
                      {{ shipment.package.weight }}</span
                    >
                  </li>
                </div>
                <div class="col-3">
                  <li>
                    <strong>Shipment Status</strong>
                    <span>{{ shipment.status }}</span>
                  </li>
                </div>
                <div class="col-5">
                  <li>
                    <strong>Tracking Number</strong>
                    <span
                      >{{ shipment.tracking_number }}
                      <button class="copy-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-copy"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                          /></svg
                        >Copy
                      </button></span
                    >
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
import Header from "../../layouts/components/HeaderComp.vue";
import Footer from "../../layouts/components/FooterComp.vue";
import PanelMenu from "../../layouts/components/PanelMenu.vue";
export default {
  components: {
    BAlert,
    BSpinner,
    Header,
    Footer,
    PanelMenu,
  },
  data() {
    return {
      errors: [],
      orders: [],
      showModal: false,
      courierDetails: {},

      current_page: 0,
      total_pages: 0,
      total_records: 0,

      page: 1,
      limit: 10,

      orderDetails: {},
      isLoading: false,
      isTableLoading: false,

      searchText: "",
      searchFrom: "",
      searchTo: "",
    };
  },
  mounted() {
    this.listPurchases();
  },
  methods: {
    listPurchases() {
      this.isTableLoading = true;
      this.page = 1;
      axios
        .get(`purchases?page=${this.page}&limit=${this.limit}&search=${this.searchText}`)
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.orders = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    searchTable() {
      if (this.searchText.length >= 3) {
        this.listPurchases();
      } else if (this.searchText.length == 0) {
        this.listPurchases();
      }
    },

    searchWithDate() {
      this.isTableLoading = true;
      this.page = 1;

      axios
        .get(
          `purchases?page=${this.page}&limit=${this.limit}&search=${this.searchText}${
            this.searchFrom ? "&from=" + this.searchFrom + "T00:00:00%2B00:00" : ""
          }${this.searchTo ? "&to=" + this.searchTo + "T23:59:59%2B00:00" : ""}`
        )
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.orders = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    exportTable() {
      this.page = 1;
      axios
        .get(
          `purchases?page=${this.page}&limit=${this.limit}&search=${this.searchText}${
            this.searchFrom ? "&from=" + this.searchFrom + "T00:00:00%2B00:00" : ""
          }${
            this.searchTo ? "&to=" + this.searchTo + "T23:59:59%2B00:00" : ""
          }&export=true`
        )
        .then((res) => {
          console.log(res);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          this.errors.push(err);
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
        });
    },

    limitTable() {
      this.isTableLoading = true;
      this.page = 1;

      axios
        .get(
          `purchases?page=${this.page}&limit=${this.limit}&search=${this.searchText}${
            this.searchFrom ? "&from=" + this.searchFrom + "T00:00:00%2B00:00" : ""
          }${this.searchTo ? "&to=" + this.searchTo + "T23:59:59%2B00:00" : ""}`
        )
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.orders = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    changePage(page) {
      this.isTableLoading = true;
      this.page = page;

      axios
        .get(
          `purchases?page=${this.page}&limit=${this.limit}&search=${this.searchText}${
            this.searchFrom ? "&from=" + this.searchFrom + "T00:00:00%2B00:00" : ""
          }${this.searchTo ? "&to=" + this.searchTo + "T23:59:59%2B00:00" : ""}`
        )
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.orders = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    viewDetails(id) {
      this.isLoading = true;
      this.showModal = true;
      axios
        .get(`purchases/${id}`)
        .then((res) => {
          console.log(res.data);
          this.orderDetails = res.data;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    downloadLabel(id) {
      console.log(id);
      axios
        .get(`purchases/${id}/download`)
        .then((res) => {
          if (res.status == 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "order_" + id + ".pdf");
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
