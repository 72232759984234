<template>
  <div class="main-hero-wrap pb-0">
    <header>
      <div class="container">
        <div class="logo">
          <a href="#">
            <img src="../../assets/logoipsum.svg" alt="" />
          </a>
        </div>
        <nav>
          <ul>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <div class="c-badge">Sale</div>
              <a href="#">Pricing</a>
            </li>
            <li>
              <a href="#">Support</a>
            </li>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
          </ul>
        </nav>
        <div class="header-buttons">
          <router-link to="/login" class="basket">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-person"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
              />
            </svg>
            User Panel
          </router-link>
          <button
            @click="logout()"
            class="basket ml-2"
            style="border: none; width: 100px"
            v-if="this.$store.getters.isAuthenticated"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-box-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
              />
              <path
                fill-rule="evenodd"
                d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
              />
            </svg>
            Logout
          </button>
          <button class="hamburger-btn">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 4.375H2.5C2.15833 4.375 1.875 4.09167 1.875 3.75C1.875 3.40833 2.15833 3.125 2.5 3.125H17.5C17.8417 3.125 18.125 3.40833 18.125 3.75C18.125 4.09167 17.8417 4.375 17.5 4.375Z"
                fill="white"
              />
              <path
                d="M17.4999 8.54175H9.60828C9.26661 8.54175 8.98328 8.25841 8.98328 7.91675C8.98328 7.57508 9.26661 7.29175 9.60828 7.29175H17.4999C17.8416 7.29175 18.1249 7.57508 18.1249 7.91675C18.1249 8.25841 17.8416 8.54175 17.4999 8.54175Z"
                fill="white"
              />
              <path
                d="M17.5 12.7083H2.5C2.15833 12.7083 1.875 12.4249 1.875 12.0833C1.875 11.7416 2.15833 11.4583 2.5 11.4583H17.5C17.8417 11.4583 18.125 11.7416 18.125 12.0833C18.125 12.4249 17.8417 12.7083 17.5 12.7083Z"
                fill="white"
              />
              <path
                d="M17.4999 16.875H9.60828C9.26661 16.875 8.98328 16.5917 8.98328 16.25C8.98328 15.9083 9.26661 15.625 9.60828 15.625H17.4999C17.8416 15.625 18.1249 15.9083 18.1249 16.25C18.1249 16.5917 17.8416 16.875 17.4999 16.875Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style></style>
